import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/furniture";
import Services from "../../Services/Services";

export default class Furniture extends Page {
  title = "home";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getApp().updateWindowTitle("Casa di Patsi Stock - ΕΠΙΠΛΑ");

    Services.get("content").then(([content]) => {
      content.getCategories().then((content) => {
        this.setData({
          "default.categories": content.getData("categories", []),
        });
      });
    });

    this.getApp().addScripts();

    this.scrollToTop();
  }
}
