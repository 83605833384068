import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const categories = component.getData("default.categories", []);
  const current_page = component.getData("default.page");
  const wishlist = component.getData("default.wishlist", []);
  const headerMessage = component.ucfirst("header-message");
  const hasHeaderMessage =
    component.getHelpers("value").getValue("has-header-message") === "yes";
  const closed = component.getHelpers("value").getValue("closed");

  return (
    <header className="header header-2 header-intro-clearance">
      {hasHeaderMessage && (
        <div className="header-message">{headerMessage}</div>
      )}

      <div className="header-middle">
        <div className="container">
          <div className="header-left">
            <button className="mobile-menu-toggler">
              <span className="sr-only">Toggle mobile menu</span>
              <i className="icon-bars"></i>
            </button>

            <a href="/" className="logo" title="Casa Di Patsi Stockhouse Home">
              <img
                src="/logo.png"
                alt="Casa Di Patsi Stockhouse"
                title="Casa Di Patsi Stockhouse Home"
                width="200"
                height="100"
              />
            </a>
          </div>
          {/* End .header-left */}

          <div className="header-center">
            <div className="header-search header-search-extended header-search-visible header-search-no-radius d-none d-lg-block">
              <a
                href="/"
                className="search-toggle"
                role="button"
                aria-label="Search"
              >
                <i className="icon-search"></i>
              </a>
              <form onSubmit={(e) => component.checkSearch(e)} method="get">
                <div className="header-search-wrapper search-wrapper-wide">
                  <label htmlFor="q" className="sr-only">
                    Aναζήτηση
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="q"
                    id="search"
                    placeholder="Aναζήτηση προϊόντος ..."
                  />
                  <button
                    className="btn btn-primary"
                    type="submit"
                    aria-label="Search"
                  >
                    <i className="icon-search"></i>
                  </button>
                </div>
                {/* End .header-search-wrapper */}
              </form>
            </div>
            {/* End .header-search */}
          </div>

          <div id="header_contact_info" className="header-right">
            <ul id="info">
              <li>
                <a href="tel:2102850233" className="tel_number phone">
                  <i className="icon-phone-square"></i>2102850233
                </a>
              </li>
              <li>
                <a href="/contact-us" className="shops">
                  <i className="icon-map-marker"></i>ΚΑΤΑΣΤΗΜΑ
                </a>
              </li>
            </ul>
            <div className="wishlist">
              <a href="/wishlist" title="Wishlist">
                <div className="icon">
                  <i className="icon-heart-o"></i>
                  <span id="wishlist_cicle" className="wishlist-count badge">
                    {wishlist.length}
                  </span>
                </div>
                <p>Αγαπημένα</p>
              </a>
            </div>
            {/* End .compare-dropdown */}
          </div>
          {/* End .header-right */}
        </div>
        {/* End .container */}
      </div>
      {/* End .header-middle */}
      <div className="mt-1" id="mobile_contact_info">
        <div className="text-center">
          <ul className="mb-0">
            <li
              className="li_xs_fix"
              style={{
                margin: "0px 3px",
              }}
            >
              <a href="tel:2102850233" className="tel_number phone">
                <i className="icon-phone"></i>
                2102850233
              </a>
            </li>
            <li
              className="li_xs_fix"
              style={{
                margin: "0px 3px",
              }}
            >
              <a href="/contact-us" className="shops">
                <i className="icon-map-marker"></i>
                ΚΑΤΑΣΤΗΜΑ
              </a>
            </li>
          </ul>
        </div>

        {closed === "true" ? (
          <span style={{ color: "#f32039" }}>
            <i
              className="la la-lightbulb-o"
              style={{
                fontSize: "18px",
                color: "#f32039",
                margin: "0px 5px 10px",
              }}
            />
            {component.trans("closed-text")}
          </span>
        ) : (
          <span>
            <i
              className="la la-lightbulb-o"
              style={{
                fontSize: "18px",
                color: "#f32039",
                margin: "0px 5px 10px",
              }}
            />
            Ανοιχτά και την
            <span className="highlight" style={{ color: "#f32039" }}>
              &nbsp;Κυριακή
            </span>
          </span>
        )}
      </div>
      <div className="header-bottom sticky-header">
        <div id="my_cont" className="container">
          <div className="header-left"></div>

          <div className="header-center">
            <nav className="main-nav" style={{ margin: "0px auto" }}>
              <ul className="menu sf-arrows">
                <li
                  className={
                    current_page === 0
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                >
                  <a href="/">ΑΡΧΙΚΗ</a>
                </li>
                <li
                  className={
                    current_page === 1 ? "active nav_liks" : "nav_liks"
                  }
                >
                  <a href="/furniture" className="sf-with-ul" title="ΕΠΙΠΛΑ">
                    ΕΠΙΠΛΑ
                  </a>

                  <ul>
                    {categories.map((category) => {
                      const { slug, name } = category;

                      return (
                        <li key={`category-${slug}`}>
                          <a
                            href={`/category/${slug}`}
                            className="category-name"
                            title={name}
                          >
                            {name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li
                  className={
                    current_page === 2
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                >
                  <a href="/about-us">STOCKHOUSE</a>
                </li>
                <li
                  className={
                    current_page === 3
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                >
                  <a href="/contact-us">ΕΠΙΚΟΙΝΩΝΙΑ</a>
                </li>
              </ul>
              {/* End .menu */}
            </nav>
            {/* End .main-nav */}
          </div>
          {/* End .header-center */}

          <div className="header-right">
            {closed === "true" ? (
              <p style={{ color: "#f32039" }}>
                <i
                  className="la la-lightbulb-o"
                  style={{
                    fontSize: "18px",
                    color: "#f32039",
                    marginRight: "3px",
                  }}
                />
                {component.trans("closed-text")}
              </p>
            ) : (
              <p>
                <i
                  className="la la-lightbulb-o"
                  style={{
                    fontSize: "18px",
                    color: "#f32039",
                    marginRight: "3px",
                  }}
                />
                Ανοιχτά και την
                <span className="highlight" style={{ color: "#f32039" }}>
                  &nbsp;Κυριακή
                </span>
              </p>
            )}
          </div>
        </div>

        {/* End .container */}
      </div>
      {/* End .header-bottom */}
    </header>
  );
};

export default ExportDefault;
