import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <div className="container">
      <div className="desktop-banner">
        <a href="/bzr">
          <img
            src={urlHelper.createAccountImageUrl(
              account,
              repository,
              "item",
              "gallery",
              "default",
              "slider_bzr"
            )}
            alt="bazaar desktop"
          />
        </a>
      </div>
      <div className="mobile-banner">
        <a href="/bzr">
          <img
            src={urlHelper.createAccountImageUrl(
              account,
              repository,
              "item",
              "gallery",
              "default",
              "slider_bzr_mobile"
            )}
            alt="bazaar mobile"
          />
        </a>
      </div>
    </div>
  );
};

export default ExportDefault;
