import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const item = component.getData("default.item", null);
  const devHelper = component.getHelpers("dev");
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  const wishlist = component.getData("default.wishlist", false);
  const images = component.getData("default.images", []);

  const [value, setValue] = React.useState("#");
  const [oldPrice, setPrice] = React.useState(false);
  const [newPrice, setNewPrice] = React.useState(false);
  const { has_sizes = false } = item || {};

  const updatePrice = (slug) => {
    setValue(slug);
    if (slug === "#") {
      setPrice(false);
      setNewPrice(false);
    } else {
      item.item_sizes.every((size) => {
        if (size.slug === slug) {
          var prices = size.extra.split(",");
          setPrice(prices[0]);
          setNewPrice(prices[1]);
          return false;
        }
        return true;
      });
    }
  };

  const colorChange = (event, slug) => {
    event.preventDefault();
    item.item_colors.forEach((color, index) => {
      if (color.slug === slug) {
        color.selected = true;
      } else {
        color.selected = false;
      }
    });
  };

  //Add or Remove product from Wishlist
  const updateWishList = (boolean) => {
    var slug = devHelper.getObjectValue(item, "slug");
    boolean
      ? component.addToWishList(slug)
      : component.removeFromWishList(slug);
  };

  const openPopup = (event) => {
    event.preventDefault();
    document.getElementById("myModal").style.display = "block";
  };

  const getFileSize = (props) => {
    const { component } = props;
    const slug = component.getPage().getParam("slug");
    const envHelper = component.getHelpers("env");
    const urlHelper = component.getHelpers("url");
    const account = envHelper.getDefaultAccount();
    const repository = envHelper.getDefaultRepository();

    return urlHelper.createAccountFileUrl(
      account,
      repository,
      "item",
      slug,
      "pdf",
      "sizes"
    );
  };

  return (
    <React.Fragment>
      <div id="main_image">
        <div className="mt-3"></div>
        {item && (
          <div className="container">
            <div className="product-details-top mb-2">
              <div className="row">
                <div className="col-md-6">
                  <div className="product-gallery">
                    <figure className="product-main-image">
                      <img
                        id="product-zoom"
                        src={component
                          .getHelpers("url")
                          .createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            devHelper.getObjectValue(item, "slug"),
                            "default",
                            "1"
                          )}
                        alt="product img"
                      />

                      <button
                        id="btn-product-gallery"
                        className="btn-product-gallery"
                      >
                        <i className="icon-arrows"></i>
                      </button>
                    </figure>
                    <div
                      id="product-zoom-gallery"
                      className="product-image-gallery"
                    >
                      {images.map((img) => (
                        <a
                          key={devHelper.getObjectValue(img, "slug")}
                          href="/"
                          onClick={(e) => e.preventDefault()}
                          className="product-gallery-item"
                          style={{
                            backgroundImage: `url(${component
                              .getHelpers("url")
                              .createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                devHelper.getObjectValue(img, "entity_slug"),
                                "default",
                                devHelper.getObjectValue(img, "slug")
                              )})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                          data-image={component
                            .getHelpers("url")
                            .createAccountImageUrl(
                              account,
                              repository,
                              "item",
                              devHelper.getObjectValue(img, "entity_slug"),
                              "default",
                              devHelper.getObjectValue(img, "slug")
                            )}
                          data-zoom-image={component
                            .getHelpers("url")
                            .createAccountImageUrl(
                              account,
                              repository,
                              "item",
                              devHelper.getObjectValue(img, "entity_slug"),
                              "default",
                              devHelper.getObjectValue(img, "slug")
                            )}
                        >
                          {" "}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="product-details">
                    <h1
                      className="product-title"
                      style={{ fontSize: "30px", marginBottom: "30px" }}
                    >
                      {component.ucfirst(
                        devHelper.getObjectValue(item, "name")
                      )}
                    </h1>

                    {item.price !== 0 && item.special_price !== 0 && (
                      <div className="mb-2">
                        <div className="product-price mb-0">
                          <span className="old-price">
                            {oldPrice
                              ? oldPrice
                              : devHelper.getObjectValue(item, "price")}{" "}
                            €
                          </span>
                          <span className="new-price">
                            {newPrice
                              ? newPrice
                              : devHelper.getObjectValue(
                                  item,
                                  "special_price"
                                )}{" "}
                            €
                          </span>
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#9b9b9b",
                            fontWeight: 400,
                          }}
                        >
                          {component.trans("vat-included")}
                        </div>
                      </div>
                    )}
                    {item.description && (
                      <h4
                        className="mb-2"
                        style={{
                          fontSize: "1.4rem",
                          fontWeight: 300,
                          fontFamily: "Arial",
                          letterSpacing: 0,
                          color: "#777",
                          lineHeight: 2,
                        }}
                      >
                        {devHelper.getObjectValue(item, "description")}
                      </h4>
                    )}

                    {item.item_sizes && item.item_sizes.length !== 0 && (
                      <div className="details-filter-row details-row-size mb-2">
                        <label htmlFor="size">Μεγέθοι:</label>
                        <div className="select-custom">
                          <select
                            name="size"
                            id="size"
                            className="form-control"
                            value={value}
                            onChange={(event) =>
                              updatePrice(event.target.value)
                            }
                          >
                            <option value="#">Επιλογή ...</option>
                            {item.item_sizes.map((size) => (
                              <option
                                key={
                                  "size-" +
                                  devHelper.getObjectValue(size, "slug")
                                }
                                value={devHelper.getObjectValue(size, "slug")}
                              >
                                {devHelper.getObjectValue(size, "value")}
                              </option>
                            ))}
                          </select>
                        </div>

                        {has_sizes && (
                          <a
                            href={getFileSize(props)}
                            className="size-guide"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="icon-th-list"></i>οδηγός μεγεθών
                          </a>
                        )}
                      </div>
                    )}

                    {item.item_colors && item.item_colors.length !== 0 && (
                      <div className="details-filter-row details-row-size mb-2">
                        <label>Χρώματα:</label>
                        <div className="product-nav product-nav-dots">
                          {item.item_colors.map((color) => (
                            <a
                              key={
                                "color-" +
                                devHelper.getObjectValue(color, "slug")
                              }
                              href="/"
                              className={
                                devHelper.getObjectValue(
                                  color,
                                  "selected",
                                  false
                                )
                                  ? "active"
                                  : ""
                              }
                              onClick={(e) =>
                                colorChange(
                                  e,
                                  devHelper.getObjectValue(color, "slug")
                                )
                              }
                              style={{
                                background:
                                  "#" +
                                  devHelper.getObjectValue(color, "value"),
                              }}
                            >
                              <span className="sr-only">
                                {devHelper.getObjectValue(color, "slug")}
                              </span>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}

                    <div
                      className="product-details-action mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="btn-cart"
                        onClick={(e) => openPopup(e)}
                        style={{ marginBottom: "10px" }}
                      >
                        <span>ζητηστε προσφορα</span>
                      </button>
                      <button
                        onClick={(e) => updateWishList(!wishlist)}
                        style={{
                          border: "none",
                          marginRight: "5px",
                          backgroundColor: "transparent",
                          color: "#f32039",
                          marginBottom: "10px",
                        }}
                      >
                        <i
                          className={wishlist ? "icon-heart" : "icon-heart-o"}
                          style={{ marginRight: "5px" }}
                        />
                        <span>
                          {wishlist
                            ? "Αφαίρεση απο Αγαπημένα"
                            : "Προσθήκη στα Αγαπημένα"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div id="myModal" className="modal">
        <div className="modal-content bg-white">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="text-center">
                <i
                  className="icon-close close-btn"
                  onClick={() => {
                    document.getElementById("myModal").style.display = "none";
                  }}
                ></i>
                <img
                  src="/logo.png"
                  className="logo"
                  alt="logo"
                  width="150"
                  height="30"
                />

                <h2 className="title mb-1">
                  Eρωτήση σχετικά με το προϊόν:{" "}
                  {component.ucfirst(devHelper.getObjectValue(item, "slug"))}
                </h2>
                {/* End .title mb-2 */}
                <p className="mb-2">
                  Χρησιμοποιήστε την παρακάτω φόρμα για να έρθετε σε επαφή με
                  την ομάδα πωλήσεων
                </p>

                <form
                  onSubmit={(e) => {
                    component.sendForm(e);
                  }}
                  className="contact-form mb-3"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cname" className="sr-only">
                        Όνομα
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cname"
                        placeholder="Όνομα *"
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Συμπληρώστε το όνομά σας!"
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      />
                    </div>
                    {/* End .col-sm-6 */}

                    <div className="col-sm-6">
                      <label htmlFor="cemail" className="sr-only">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="cemail"
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Συμπληρώστε το email σας!"
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        placeholder="Email *"
                        required
                      />
                    </div>
                    {/* End .col-sm-6 */}
                  </div>
                  {/* End .row */}

                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" className="sr-only">
                        Τηλέφωνο
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Συμπληρώστε το τηλέφωνό σας!"
                          )
                        }
                        id="cphone"
                        onInput={(e) => e.target.setCustomValidity("")}
                        placeholder="Τηλέφωνο *"
                        required
                      />
                    </div>
                    {/* End .col-sm-6 */}

                    <div className="col-sm-6">
                      <label htmlFor="csubject" className="sr-only">
                        Θεμα
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="csubject"
                        placeholder="Θεμα"
                      />
                    </div>
                    {/* End .col-sm-6 */}
                  </div>
                  {/* End .row */}

                  <label htmlFor="cmessage" className="sr-only">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    cols="30"
                    rows="4"
                    id="cmessage"
                    required
                    placeholder="Τι έχετε στο μυαλό σας;"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Συμπληρώστε το μήνυμά σας!")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                  ></textarea>

                  <button
                    type="submit"
                    className="btn btn-outline-primary-2 btn-minwidth-sm"
                  >
                    <span>ΥΠΟΒΟΛΗ</span>
                    <i className="icon-long-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
