import App from "../../modules/Core/Components/App";
import template from "../templates/app";

export default class MainApp extends App {
  template = template;

  getOnResizeResolusion(width) {
    if (width < 500) {
      return "mobile";
    }

    if (width >= 500) {
      return "default";
    }
  }

  updateWindowTitle(title) {
    this.getPage().setWindowTitle(title);

    window.dataLayer.push({
      event: "pageviewCustomEvent",
      pagePath: window.location.pathname,
      pageTitle: title,
    });
    return this;
  }

  addScripts() {
    setTimeout(() => {
      this.getPage().addScripts({
        "jquery.min": "/assets/js/jquery.min",
        "bootstrap.bundle.min": "/assets/js/bootstrap.bundle.min",
        "jquery.hoverIntent.min": "/assets/js/jquery.hoverIntent.min",
        "jquery.waypoints.min": "/assets/js/jquery.waypoints.min",
        "superfish.min": "/assets/js/superfish.min",
        "bootstrap-input-spinner": "/assets/js/bootstrap-input-spinner",
        "jquery.elevateZoom.min": "/assets/js/jquery.elevateZoom.min",
        "jquery.plugin.min": "/assets/js/jquery.plugin.min",
        "jquery.countdown.min": "/assets/js/jquery.countdown.min",
        "demo-2": "/assets/js/demos/demo-2",
        "jquery.magnific-popup.min": "/assets/js/jquery.magnific-popup.min",
        "owl.carousel.min": "/assets/js/owl.carousel.min",
        main: "/assets/js/main",
      });
    }, 1000);

    return this;
  }

  sendToGA4(product, event, amount) {
    if (product) {
      const { slug, name, price, special_price } = product;

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: event,
        ecommerce: {
          items: [
            {
              item_id: slug,
              item_name: name,
              affiliation: "Casa Di Patsi Stockhouse",
              index: 0,
              item_brand: "Casa Di Patsi Stockhouse",
              price: special_price ? special_price : price,
              quantity: amount || 1,
            },
          ],
        },
      });
    }
  }
}
