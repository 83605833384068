import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();
  const sliders = component.getData("default.sliders", []);

  const classes = [
    "col-md-12 col-lg-5",
    "col-md-6 col-lg-3",
    "col-md-6 col-lg-4",
  ];

  return (
    <>
      {sliders.length !== 0 && (
        <>
          <div className="mb-3 mb-lg-5"></div>

          <div className="banner-group">
            <div className="container">
              <div className="row">
                {sliders.map((slider, index) => {
                  const { slug, name, url, title, btn_text } =
                    devHelper.getObjectValue(slider, "_values");
                  if (index === 3) {
                    return <div key={slug}></div>;
                  } else if (index === 2) {
                    return (
                      <div key={slug} className={classes[index]}>
                        <div className="banner banner-large banner-overlay">
                          <a
                            href={url}
                            style={{
                              height: "277px",
                              backgroundImage: `url(${urlHelper.createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                "gallery",
                                "default",
                                slug
                              )})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                            aria-label={name}
                          >
                            {" "}
                          </a>
                          <div className="banner-content banner-content-bottom">
                            {title && (
                              <h1 className="banner-title text-white">
                                {title}
                              </h1>
                            )}
                            {btn_text && (
                              <a
                                href={url}
                                className="btn btn-outline-white banner-link"
                              >
                                {btn_text}
                                <i className="icon-long-arrow-right" />
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="banner banner-large banner-overlay">
                          <a
                            href={devHelper.getObjectValue(
                              sliders[index + 1],
                              "_values.url"
                            )}
                            style={{
                              height: "277px",
                              backgroundImage: `url(${urlHelper.createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                "gallery",
                                "default",
                                devHelper.getObjectValue(
                                  sliders[index + 1],
                                  "_values.slug"
                                )
                              )})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                            aria-label={devHelper.getObjectValue(
                              sliders[index + 1],
                              "_values.name"
                            )}
                          >
                            {" "}
                          </a>
                          <div className="banner-content banner-content-bottom">
                            {devHelper.getObjectValue(
                              sliders[index + 1],
                              "_values.title"
                            ) && (
                              <h1 className="banner-title text-white">
                                {devHelper.getObjectValue(
                                  sliders[index + 1],
                                  "_values.title"
                                )}
                              </h1>
                            )}
                            {devHelper.getObjectValue(
                              sliders[index + 1],
                              "_values.btn_text"
                            ) && (
                              <a
                                href={devHelper.getObjectValue(
                                  sliders[index + 1],
                                  "_values.url"
                                )}
                                className="btn btn-outline-white banner-link"
                              >
                                {devHelper.getObjectValue(
                                  sliders[index + 1],
                                  "_values.btn_text"
                                )}
                                <i className="icon-long-arrow-right" />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={slug} className={classes[index]}>
                        <div className="banner banner-large banner-overlay">
                          <a
                            href={url}
                            style={{
                              height: "574px",
                              backgroundImage: `url(${urlHelper.createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                "gallery",
                                "default",
                                slug
                              )})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                            aria-label={name}
                          >
                            {" "}
                          </a>
                          <div className="banner-content banner-content-bottom">
                            {title && (
                              <h1 className="banner-title text-white">
                                {title}
                              </h1>
                            )}
                            {btn_text && (
                              <a
                                href={url}
                                className="btn btn-outline-white banner-link"
                              >
                                {btn_text}
                                <i className="icon-long-arrow-right" />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <div className="mb-3"></div>
        </>
      )}
    </>
  );
};

export default ExportDefault;
