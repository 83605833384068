import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/navbar";
import Services from "../../Services/Services";

export default class Navbar extends Component {
  id = "navbar";
  group = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    Services.get("content").then(([content]) => {
      content.getCategories().then((content) => {
        this.setData({
          "default.categories": content.getData("categories", []),
          "default.page": this.props.page,
        });
      });
    });

    this.checkWishlist();
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();

      this.setData({
        "default.wishlist": wishlist,
      });
    });
  }

  checkSearch(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search").value.length > 1) {
      window.location.href =
        devHelper + "/results/" + document.getElementById("search").value;
    }
  }
}
