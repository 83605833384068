import React from "react";
import PoductListView from "../../../../Components/Widget/ProductListView";

const ExportDefault = (props) => {
  const { component } = props;
  const category_items = component.getData("default.category_items", []);
  const category_name = component.getData("default.category_name", null);

  const devHelper = component.getHelpers("dev");

  return (
    <>
      <div
        className="page-header text-center"
        style={{ backgroundColor: "unset" }}
      >
        <div className="container">
          <h1 className="page-title">
            {category_name &&
              category_name.name &&
              component.ucfirst(category_name.name)}
          </h1>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="products mb-3">
                <div className="row justify-content-center">
                  {category_items &&
                    category_items.map((item) => (
                      <PoductListView
                        key={devHelper.getObjectValue(item, "slug")}
                        {...{
                          ...props,
                          ...{
                            product: item,
                          },
                        }}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportDefault;
