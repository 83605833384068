import React from "react";

const ExportDefault = (props) => {
  return (
    <div className="page-content">
      <div className="container mt-3">
        <div className="about-text text-center">
          <div className="mb-2" style={{ fontSize: "22px", color: "#333" }}>
            Μοναδικές προσφορές σας περιμένουν στο{" "}
            <b>Casa di Patsi Stock House, στη Μεταμόρφωση!</b>
          </div>
          <div className="mb-2" style={{ fontSize: "22px", color: "#333" }}>
            Το Bazaar ανοίγει τις πόρτες του{" "}
            <b>
              Κυριακή 6 Οκτωβρίου από τις 10:00 το πρωί μέχρι τις 6:00 το
              απόγευμα.
            </b>
          </div>
          <div className="mb-2" style={{ fontSize: "22px", color: "#333" }}>
            Η Casa di Patsi σας προσκαλεί να γνωρίσετε από κοντά την απλότητα
            και την ξεχωριστή αισθητική των επίπλων της, με τιμές και σχέδια που
            θα σας εντυπωσιάσουν.
          </div>
          <div className="mb-2" style={{ fontSize: "22px", color: "#333" }}>
            <b>
              <a
                href="https://www.google.com/maps/place/CASA+DI+PATSI+Stockhouse+%CE%99%CF%84%CE%B1%CE%BB%CE%B9%CE%BA%CE%AC+%CE%AD%CF%80%CE%B9%CF%80%CE%BB%CE%B1/@38.0678071,23.7552914,17z/data=!4m5!3m4!1s0x0:0xaed40861765ff4d9!8m2!3d38.0678071!4d23.7552914"
                rel="noreferrer"
                target="_blank"
                style={{
                  color: "#333",
                  "&:hover,&:focus": { color: "#f32039" },
                }}
              >
                <i className="icon-map-marker" style={{ marginRight: "8px" }} />
                Ζακύνθου 2 και Οδυσσέως, Μεταμόρφωση
              </a>
            </b>
          </div>
          <div className="mb-2" style={{ fontSize: "22px", color: "#333" }}>
            <b>
              <a
                style={{
                  color: "#333",
                  "&:hover,&:focus": { color: "#f32039" },
                }}
                href="tel:2102850233"
              >
                <i className="icon-phone" style={{ marginRight: "8px" }} />
                2102850233
              </a>
            </b>
          </div>
        </div>
        {/* End .row */}
      </div>
      {/* End .container */}
    </div>
  );
};

export default ExportDefault;
