import React from "react";
import Navbar from "../../../Components/Widget/Navbar";
import MobileDrawer from "../../../Components/Widget/MobileDrawer";
import FirstSection from "../widgets/stockhouse/first_section";
import Footer from "../../../Components/Widget/Footer";
import Scroll from "../widgets/scroll";
// import Popup from "../widgets/popup";

const ExportDefault = (props) => {
  return (
    <>
      <div className="page-wrapper">
        <Navbar
          {...{
            ...props,
            ...{
              page: 2,
            },
          }}
        />
        <main className="main">
          <FirstSection {...props} />
        </main>
        <Footer {...props} />
      </div>
      <Scroll {...props} />
      <MobileDrawer {...props} />
      {/* <Popup {...props} /> */}
    </>
  );
};

export default ExportDefault;
