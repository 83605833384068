import React from "react";

const ExportDefault = (props) => {
  const { component } = props;

  const categories = component.getData("default.categories", []);

  return (
    <React.Fragment>
      <div className="mobile-menu-overlay"></div>
      <div className="mobile-menu-container mobile-menu-light">
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close">
            <i className="icon-close"></i>
          </span>

          <form
            onSubmit={(e) => component.checkSearchMobile(e)}
            method="get"
            className="mobile-search"
          >
            <label htmlFor="mobile-search" className="sr-only">
              Aναζήτηση
            </label>
            <input
              type="text"
              className="form-control"
              name="mobile-search"
              id="search_mobile"
              placeholder="Aναζήτηση προϊόντος ..."
            />
            <button
              className="btn btn-primary"
              id="btn-mobile-search"
              type="submit"
            >
              <i className="icon-search"></i>
            </button>
          </form>
          <nav className="mobile-nav">
            <ul className="mobile-menu">
              <li className="active nav_liks">
                <a href="/">ΑΡΧΙΚΗ</a>
              </li>

              <li>
                <a href="/furniture" className="sf-with-ul nav_liks">
                  ΕΠΙΠΛΑ
                </a>

                <ul>
                  {categories.map((category) => {
                    const { slug, name } = category;
                    return (
                      <li key={`category-${slug}`}>
                        <a href={`/category/${slug}`} className="category-name">
                          {name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li>
                <a className="nav_liks" href="/about-us">
                  STOCKHOUSE
                </a>
              </li>
              {/* <li>
                <a className="nav_liks" href="/faq">
                  ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ
                </a>
              </li> */}
              <li>
                <a className="nav_liks" href="/contact-us">
                  ΕΠΙΚΟΙΝΩΝΙΑ
                </a>
              </li>
            </ul>
          </nav>
          {/* End .mobile-nav */}

          <div className="social-icons">
            <a
              href="https://www.facebook.com/casadipatsi"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Facebook"
            >
              <i className="icon-facebook-f"></i>
            </a>
            <a
              href="https://twitter.com/CasadiPatsi"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-twitter"></i>
            </a>
            <a
              href="https://gr.pinterest.com/casadipatsi/_created/"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-pinterest"></i>
            </a>
            <a
              href="https://www.instagram.com/casadipatsi/"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-instagram"></i>
            </a>
          </div>
          {/* End .social-icons */}
        </div>
        {/* End .mobile-menu-wrapper */}
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
