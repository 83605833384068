import Page from "../../../modules/Core/Components/Page";

const redirects = {
  "/index.php/sofa.html": "category/sofas",
  "/index.php/mikroepipla.html": "category/others",
  "/index.php/table.html": "category/tables",
  "/index.php/coffee-table.html": "category/living-room-tables",
  "/index.php/bed.html": "category/beds",
  "/index.php/polythrones.html": "category/armchairs",
  "/index.php/faq": "/about-us",
};

export default class Redirect extends Page {
  title = "redirect";

  onLoad(data) {
    super.onLoad(data);

    const path = this.props.location.pathname;

    if (redirects[path]) {
      this.redirect("/" + redirects[path]);
    } else {
      this.redirect("/");
    }
  }
}
