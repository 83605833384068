import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/contact";

export default class Contact extends Page {
  title = "contact";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getApp().updateWindowTitle("Casa di Patsi Stock - ΕΠΙΚΟΙΝΩΝΙΑ");

    this.getApp().addScripts();

    this.scrollToTop();
  }
}
