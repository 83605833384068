import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/home";

export default class Home extends Page {
  title = "home";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getApp().addScripts();

    this.scrollToTop();

    // this.setSeoData();
  }

  // async setSeoData() {
  //   const account = this.getHelpers("env").getDefaultAccount();
  //   const repository = this.getHelpers("env").getDefaultRepository();

  //   const translations = await this.getHelpers("translate").scope({
  //     account,
  //     repository,
  //     entity: "item",
  //     entity_slug: "seo",
  //     scope: "default",
  //   });

  //   const description = translations.find(
  //     (translation) => translation.slug === "default-description"
  //   ) || { translation: "" };

  //   const keywords = translations.find(
  //     (translation) => translation.slug === "default-keywords"
  //   ) || { translation: "" };

  //   document
  //     .querySelector('meta[name="description"]')
  //     .setAttribute("content", description.translation);

  //   document
  //     .querySelector('meta[name="keywords"]')
  //     .setAttribute("content", keywords.translation);
  // }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    this.addScripts({
      "owl.carousel.min": "/assets/js/owl.carousel.min",
      main: "/assets/js/main",
    });
  }
}
