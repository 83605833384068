import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/product";

export default class Product extends Page {
  title = "product";
  mainLoaded = false;

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getApp().addScripts();

    this.scrollToTop();

    setTimeout(() => {
      window
        .$(".product-gallery-item")
        .after()
        .click(() => {
          window.scrollTo({
            top: 180,
            left: 0,
            behavior: "smooth",
          });
        });
    }, 1500);
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    this.getApp().addScripts();
  }
}
