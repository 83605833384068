import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/product/first_section";
import Services from "../../Services/Services";

export default class Product extends Component {
  id = "product";
  group = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    const env = this.getHelpers("env");
    const slug = this.getPage().getParam("slug", "");

    this.getHelpers("file")
      .scope({
        account: env.getDefaultAccount(),
        repository: env.getDefaultRepository(),
        entity: "item",
        entity_slug: slug,
        scope: "default",
      })
      .then((data) => {
        this.setData({ "default.images": data });
      });

    Services.get("content").then(([contentService]) => {
      contentService
        .getItem(slug)
        .then((contentService) => {
          const { response } = contentService;
          const item = response.getData("item", "");
          const dev = this.getHelpers("dev");
          this.getApp().updateWindowTitle(
            "Casa di Patsi Stock - " +
              this.ucfirst(dev.getObjectValue(item, "name"))
          );

          this.setData({
            "default.item": item,
          });

          this.addToRecentlyViewed(dev.getObjectValue(item, "slug"));
          this.getApp().sendToGA4(item, "view_item");
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    this.checkWishlist();
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      var slug = this.getPage().getParam("slug", null);
      this.setData({
        "default.wishlist": wishlist.some((el) => el === slug),
      });
    });
  }
  addToWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.addToWishList(slug, this);
    });
  }

  removeFromWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.removeFromWishList(slug, this);
    });
  }

  addToRecentlyViewed(slug) {
    Services.get("recentlyViewed").then(([recentlyViewed]) => {
      recentlyViewed.addToRecentlyViewed(slug);
    });

    return this;
  }

  sendForm(e) {
    e.preventDefault();
    if (isNaN(document.getElementById("cphone").value)) {
      document
        .getElementById("cphone")
        .setCustomValidity("Το τηλέφωνο πρεπει να περιέχει μόνο αριθμούς!");
    } else {
      const message = {};
      message["item"] = this.getPage().getParam("slug");
      message["name"] = document.getElementById("cname").value;
      message["email"] = document.getElementById("cemail").value;
      message["phone"] = document.getElementById("cphone").value;
      message["subject"] = document.getElementById("csubject").value;
      message["message"] = document.getElementById("cmessage").value;
      message["link"] = window.location.href;

      Services.get("hook").then(async ([Hook]) => {
        const response = await Hook.post("contact-us", message);

        if (response.isValid()) {
          document.getElementById("cname").value = "";
          document.getElementById("cemail").value = "";
          document.getElementById("cphone").value = "";
          document.getElementById("csubject").value = "";
          document.getElementById("cmessage").value = "";
          document.getElementById("myModal").style.display = "none";
        }
      });
    }
  }
}
