import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const category_items = component.getData("default.category_items", null);
  const devHelper = component.getHelpers("dev");
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();
  const word = component.getCurrentWord();

  const addToWishList = (event, index, slug) => {
    event.preventDefault();
    category_items[index].added = true;
    component.addToWishList(slug);
  };

  const removeFromWishList = (event, index, slug) => {
    event.preventDefault();
    category_items[index].added = false;
    component.removeFromWishList(slug);
  };

  return (
    <React.Fragment>
      <div
        className="mt-2 mb-2 text-center"
        style={{ backgroundColor: "unset" }}
      >
        <div className="container">
          <h3 className="error-title">Αποτελέσματα αναζήτησης για: «{word}»</h3>
        </div>
        {/* End .container */}
      </div>
      {/* End .page-header */}
      <div className="page-content">
        {category_items && category_items.length !== 0 && (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="products mb-3">
                  <div className="row justify-content-center">
                    {category_items.map((item, index) => (
                      <div
                        key={`item-${devHelper.getObjectValue(item, "slug")}`}
                        className="col-lg-4 col-md-6 col-sm-6 col-xs-12"
                      >
                        <div className="product product-7 text-center">
                          <figure className="product-media">
                            <a
                              href={`/product/${devHelper.getObjectValue(
                                item,
                                "slug"
                              )}`}
                              style={{
                                height: "250px",
                                backgroundImage: `url(${component
                                  .getHelpers("url")
                                  .createAccountImageUrl(
                                    account,
                                    repository,
                                    "item",
                                    devHelper.getObjectValue(item, "slug"),
                                    "default",
                                    "1"
                                  )})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            >
                              {" "}
                            </a>

                            <div className="product-action-vertical">
                              {item.added ? (
                                <a
                                  href="/"
                                  title="Wishlist"
                                  onClick={(e) =>
                                    removeFromWishList(
                                      e,
                                      index,
                                      devHelper.getObjectValue(item, "slug")
                                    )
                                  }
                                  className="btn-product-icon btn-expandable"
                                >
                                  <i className="icon-heart"></i>
                                  <span>Αφαίρεση απο Αγαπημένα</span>
                                </a>
                              ) : (
                                <a
                                  href="/"
                                  title="Wishlist"
                                  onClick={(e) =>
                                    addToWishList(
                                      e,
                                      index,
                                      devHelper.getObjectValue(item, "slug")
                                    )
                                  }
                                  className="btn-product-icon btn-expandable"
                                >
                                  <i className="icon-heart-o"></i>
                                  <span>Προσθήκη στα Αγαπημένα</span>
                                </a>
                              )}
                            </div>
                            {/* End .product-action-vertical */}
                          </figure>
                          {/* End .product-media */}

                          <div className="product-body">
                            <h3 className="product-title">
                              <a
                                href={`/product/${devHelper.getObjectValue(
                                  item,
                                  "slug"
                                )}`}
                              >
                                {devHelper.getObjectValue(item, "name")}
                              </a>
                            </h3>
                            {/* End .product-title */}
                            {item.price !== 0 && item.special_price !== 0 && (
                              <div>
                                <span className="old-price">
                                  {devHelper.getObjectValue(item, "price")} €
                                </span>
                                <span className="new-price">
                                  {devHelper.getObjectValue(
                                    item,
                                    "special_price"
                                  )}{" "}
                                  €
                                </span>{" "}
                              </div>
                            )}
                            {/* End .product-price */}
                          </div>
                          {/* End .product-body */}
                        </div>
                        {/* End .product */}
                      </div>
                    ))}
                  </div>
                  {/* End .row */}
                </div>
                {/* End .products */}
              </div>
              {/* End .col-lg-9 */}
            </div>
            {/* End .row */}
          </div>
        )}
        {/* End .container */}
        {category_items && category_items.length === 0 && (
          <div
            className="error-content text-center"
            style={{
              backgroundImage: "url(/assets/error-bg.jpg",
              paddingTop: "30px",
              minHeight: "400px",
            }}
          >
            <div className="container">
              <p>Η αναζήτησή σας δεν είχε αποτελέσματα.</p>
              <a href="/" className="btn btn-outline-primary-2 btn-minwidth-lg">
                <span>Επιστροφή στην Αρχική</span>
                <i className="icon-long-arrow-right"></i>
              </a>
            </div>
          </div>
        )}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
