import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/mobile_drawer";
import Services from "../../Services/Services";

export default class MobileDrawer extends Component {
  id = "mobile_drawer";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    Services.get("content").then(([content]) => {
      content.getCategories().then((content) => {
        this.setData({
          "default.categories": content.getData("categories", []),
        });
      });
    });
  }

  checkSearchMobile(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search_mobile").value.length > 1) {
      window.location.href =
        devHelper +
        "/results/" +
        document.getElementById("search_mobile").value;
    }
  }
}
