import Helpers from "../../../modules/Core/Helpers/Helpers";
import Service from "../Service";

class Wishlist extends Service {
  getWishlist() {
    const wishlist = Helpers.State.get("wishlist");
    if (wishlist) {
      var array = wishlist.split(",");
      return array;
    } else {
      return [];
    }
  }

  addToWishList(slug, component) {
    const Env = Helpers.Env;
    const stateHelper = Helpers.State;

    // stateHelper.remove("wishlist", {
    //   domain: Env.get("domain"),
    //   path: "/",
    // });

    var array = [];
    var wishlist = stateHelper.get("wishlist", null);

    if (wishlist) {
      array = wishlist.split(",");
      if (!array.includes(slug)) {
        array.push(slug);
      }
      stateHelper.set("wishlist", array.join(","), {
        domain: Env.get("domain"),
        path: "/",
      });
    } else {
      array.push(slug);
      stateHelper.set("wishlist", array.join(","), {
        domain: Env.get("domain"),
        path: "/",
      });
    }
    component
      .getComponents()
      .findByGroup("wishlist")
      .forEach((comp) => {
        comp.checkWishlist();
      });
  }

  removeFromWishList(slug, component) {
    const Env = Helpers.Env;
    const stateHelper = Helpers.State;

    var array = stateHelper.get("wishlist").split(",");
    var index = array.findIndex((el) => el === slug);

    array.splice(index, 1);

    stateHelper.set("wishlist", array.join(","), {
      domain: Env.get("domain"),
      path: "/",
    });

    component
      .getComponents()
      .findByGroup("wishlist")
      .forEach((comp) => {
        comp.checkWishlist();
      });
  }
}

export default new Wishlist();
