import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();
  const wishlist = component.getData("default.wishlist", []);

  return (
    <div className="page-content">
      {wishlist.lenth !== 0 && (
        <div className="container">
          <table className="table table-wishlist table-mobile">
            <thead>
              <tr>
                <th>Προϊόν</th>
                <th>Τιμή</th>
                <th>Κατάσταση αποθέματος</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {wishlist.map((item) => (
                <tr key={`item-${devHelper.getObjectValue(item, "slug")}`}>
                  <td className="product-col">
                    <div className="product">
                      <figure
                        className="product-media"
                        style={{ maxWidth: "150px" }}
                      >
                        <a
                          href={`/product/${devHelper.getObjectValue(
                            item,
                            "slug"
                          )}`}
                        >
                          <img
                            src={component
                              .getHelpers("url")
                              .createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                devHelper.getObjectValue(item, "slug"),
                                "default",
                                "1"
                              )}
                            alt="Product"
                          />
                        </a>
                      </figure>

                      <h3 className="product-title">
                        <a
                          href={`/product/${devHelper.getObjectValue(
                            item,
                            "slug"
                          )}`}
                        >
                          {devHelper.getObjectValue(item, "name")}
                        </a>
                      </h3>
                    </div>
                  </td>
                  <td className="price-col">
                    {item.price !== 0 && item.special_price !== 0 && (
                      <div>
                        <span className="old-price">
                          {devHelper.getObjectValue(item, "price")} €
                        </span>
                        <span className="new-price">
                          {devHelper.getObjectValue(item, "special_price")} €
                        </span>
                      </div>
                    )}
                  </td>
                  <td className="stock-col">
                    <span className="in-stock">Σε απόθεμα</span>
                  </td>
                  <td className="remove-col">
                    <button
                      className="btn-remove"
                      onClick={() =>
                        component.removeFromWishList(
                          devHelper.getObjectValue(item, "slug")
                        )
                      }
                    >
                      <i className="icon-close"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ExportDefault;
