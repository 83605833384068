import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/products";
import Services from "../../Services/Services";

export default class Products extends Page {
  title = "home";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    Services.get("content").then(([contentService]) => {
      contentService
        .getCategories()
        .then((contentService) => {
          const categories = contentService.getData("categories", []);
          const category_slug = this.getCurrentCategory();
          const category_name = categories.find(
            (cat) => cat.slug === category_slug
          );

          this.getApp().updateWindowTitle(
            "Casa di Patsi Stock - " + this.ucfirst(category_name.name)
          );

          this.setData({
            "default.categories": categories,
            "default.category_name": category_name,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    Services.get("content").then(([contentService]) => {
      contentService
        .getCategoryItems(this.getPage().getParam("slug", ""))
        .then((contentService) => {
          this.setData({
            "default.category_items": contentService
              .getData("category-items", [])
              .sort(function (a, b) {
                a.position =
                  typeof a.position === "undefined" ? 10000 : a.position;
                b.position =
                  typeof b.position === "undefined" ? 10000 : b.position;

                return a.position - b.position;
              }),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    this.getApp().addScripts();

    this.scrollToTop();
  }

  getCurrentCategory() {
    return this.getPage().getParam("slug", "");
  }
}
