import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();
  const closed = component.getHelpers("value").getValue("closed");

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="mt-3"></div>
          <div className="row">
            <div className="col-lg-6 mb-2 mb-lg-0">
              <h2 className="title mb-1">Στοιχεία Eπικοινωνίας</h2>
              {/* End .title mb-2 */}
              <div className="row">
                <div className="col-sm-7">
                  <div className="contact-info">
                    <figure className="store-media mb-2 mb-lg-0">
                      <img
                        src={urlHelper.createAccountImageUrl(
                          account,
                          repository,
                          "item",
                          "gallery",
                          "default",
                          "stockhouse"
                        )}
                        alt="img"
                      />
                    </figure>
                    <ul className="contact-list mt-1">
                      <li>
                        <i className="icon-map-marker"></i>
                        <a
                          href="https://www.google.com/maps/place/CASA+DI+PATSI+Stockhouse/@38.0678071,23.7531027,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1a1f89df1a515:0xaed40861765ff4d9!8m2!3d38.0678116!4d23.7552822"
                          rel="noreferrer"
                          className="btn btn-link"
                          target="_blank"
                        >
                          Ζακύνθου 2, Μεταμόρφωση 144 52
                        </a>
                      </li>
                      <li>
                        <i className="icon-phone"></i>
                        <a href="tel:2102850233">2102850233</a>
                      </li>
                      <li>
                        <i className="icon-envelope"></i>
                        <a href="mailto:info@casadipatsistock.gr">
                          info@casadipatsistock.gr
                        </a>
                      </li>
                    </ul>
                    {/* End .contact-list */}
                  </div>
                  {/* End .contact-info */}
                </div>
                {/* End .col-sm-7 */}

                <div className="col-sm-5">
                  <div className="contact-info">
                    <ul className="contact-list">
                      <li>
                        <i className="icon-clock-o"></i>
                        <span className="text-dark">
                          Δευτέρα,Τετάρτη,Σάββατο
                        </span>{" "}
                        <br />
                        9:30π.μ.–4:30μ.μ.
                        <br />
                        <span className="text-dark">
                          Τρίτη,Πέμπτη,Παρασκευή
                        </span>{" "}
                        <br />
                        9:30π.μ.–2:30μ.μ.
                        <br />
                        5:00μ.μ.–8:00μ.μ. <br />
                        {closed !== "true" && (
                          <>
                            <span className="text-dark">Κυριακή</span>
                            <br />
                            10:00π.μ.–2:30μ.μ.
                          </>
                        )}
                      </li>
                    </ul>
                    {/* End .contact-list */}
                  </div>
                  {/* End .contact-info */}
                </div>
                {/* End .col-sm-5 */}
              </div>
              {/* End .row */}
            </div>
            {/* End .col-lg-6 */}
            <div className="col-lg-6">
              <h2 className="title mb-1">Έχετε οποιαδήποτε ερωτήση;</h2>
              {/* End .title mb-2 */}
              <p className="mb-2">
                Χρησιμοποιήστε την παρακάτω φόρμα για να έρθετε σε επαφή με την
                ομάδα πωλήσεων
              </p>

              <form
                onSubmit={(e) => {
                  component.sendForm(e);
                }}
                className="contact-form mb-3"
              >
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="cname" className="sr-only">
                      Όνομα
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cname"
                      placeholder="Όνομα *"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Συμπληρώστε το όνομά σας!")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}

                  <div className="col-sm-6">
                    <label htmlFor="cemail" className="sr-only">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="cemail"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Συμπληρώστε το email σας!")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      placeholder="Email *"
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}
                </div>
                {/* End .row */}

                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="cphone" className="sr-only">
                      Τηλέφωνο
                    </label>
                    <input
                      className="form-control"
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Συμπληρώστε το τηλεφωνό σας!"
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      id="cphone"
                      placeholder="Τηλέφωνο *"
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}

                  <div className="col-sm-6">
                    <label htmlFor="csubject" className="sr-only">
                      Θεμα
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="csubject"
                      placeholder="Θεμα"
                    />
                  </div>
                  {/* End .col-sm-6 */}
                </div>
                {/* End .row */}

                <label htmlFor="cmessage" className="sr-only">
                  Message
                </label>
                <textarea
                  className="form-control"
                  cols="30"
                  rows="4"
                  id="cmessage"
                  required
                  placeholder="Τι έχετε στο μυαλό σας;"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Συμπληρώστε το μήνυμά σας!")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                ></textarea>

                <button
                  type="submit"
                  className="btn btn-outline-primary-2 btn-minwidth-sm"
                >
                  <span>ΥΠΟΒΟΛΗ</span>
                  <i className="icon-long-arrow-right"></i>
                </button>
              </form>
              {/* End .contact-form */}
            </div>
            {/* End .col-lg-6 */}
          </div>
          {/* End .row */}
          {/* <hr className="mt-4 mb-5" /> */}
        </div>
        {/* End .container */}
        <div id="custom_map" className="map"></div>
        {/* End #map */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
