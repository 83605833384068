import React from "react";
import Navbar from "../../../Components/Widget/Navbar";
import MobileDrawer from "../../../Components/Widget/MobileDrawer";
import BannersGrid from "../../../Components/Widget/Sliders/BannersGrid";
import RecentlyViewed from "../../../Components/Widget/RecentlyViewed";
import Footer from "../../../Components/Widget/Footer";
import Scroll from "../widgets/scroll";

import MainSlider from "../../../Components/Widget/Sliders/MainSlider";
// import BazaarSlider from "../widgets/sliders/bazaar_slider";

const ExportDefault = (props) => {
  return (
    <>
      <div className="page-wrapper">
        <Navbar
          {...{
            ...props,
            ...{
              page: 0,
            },
          }}
        />
        <main className="main">
          <MainSlider
            {...{
              ...props,
              ...{
                fscope: "slider-main",
              },
            }}
          />
          {/* <BazaarSlider {...props} /> */}
          <BannersGrid
            {...{
              ...props,
              ...{
                fscope: "banners-grid",
              },
            }}
          />
          <RecentlyViewed {...props} />
        </main>
        <Footer {...props} />
      </div>
      <Scroll {...props} />
      <MobileDrawer {...props} />
      {/* <Popup {...props} /> */}
    </>
  );
};

export default ExportDefault;
