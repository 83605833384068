import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/contact/first_section";
import Services from "../../Services/Services";

export default class Contact extends Component {
  id = "contact";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    const uluru = { lat: 38.0675964, lng: 23.7551095 };

    this.map = new window.google.maps.Map(
      document.getElementById("custom_map"),
      {
        zoom: 16,
        center: uluru,
      }
    );

    var marker = new window.google.maps.Marker({
      position: uluru,
      map: this.map,
    });

    window.google.maps.event.addListener(marker, "click", function () {
      this.map.panTo(this.getPosition());
      this.map.setZoom(19);
    });
  }

  sendForm(e) {
    e.preventDefault();

    const message = {};

    message["name"] = document.getElementById("cname").value;
    message["email"] = document.getElementById("cemail").value;
    message["phone"] = document.getElementById("cphone").value;
    message["subject"] = document.getElementById("csubject").value;
    message["message"] = document.getElementById("cmessage").value;

    Services.get("hook").then(async ([Hook]) => {
      const response = await Hook.post("contact-us", message);

      if (response.isValid()) {
        document.getElementById("cname").value = "";
        document.getElementById("cemail").value = "";
        document.getElementById("cphone").value = "";
        document.getElementById("csubject").value = "";
        document.getElementById("cmessage").value = "";
      }
    });
  }
}
