import Hook from "./Hook";
import Content from "./Content";
import Wishlist from "./Wishlist";
import RecentlyViewed from "./RecentlyViewed";

const ExportDefault = {
  Hook,
  Content,
  Wishlist,
  RecentlyViewed,
};

export default ExportDefault;
