import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/banners_grid";
import Services from "../../../Services/Services";

export default class BannersGrid extends Component {
  id = "banners_grid";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSliders();
  }

  getSliders() {
    Services.get("hook").then(([service]) => {
      service
        .exec("statics", {
          params: { scope: this.props.fscope },
        })
        .then((response) => {
          const sliders = response.getData() || {};

          this.setData({
            "default.sliders": sliders.sort(function (a, b) {
              a.position =
                typeof a._values.position === "undefined"
                  ? 10000
                  : a._values.position;
              b.position =
                typeof b._values.position === "undefined"
                  ? 10000
                  : b._values.position;

              return a._values.position - b._values.position;
            }),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
}
