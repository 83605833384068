import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/footer";

export default class Footer extends Component {
  id = "footer";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
  }
}
