import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/recently_viewed/product_recently_viewed";
import Services from "../../Services/Services";

export default class ProductRecentlyViewed extends Component {
  id = "product-recently-viewed";
  group = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setProduct();

    this.checkWishlist();
  }

  setProduct() {
    var product = { ...this.props.product };
    this.setData({
      "default.product": product,
    });
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      var product = this.getData("default.product");
      this.setData({
        "default.wishlist": wishlist.some((el) => el === product.slug),
      });
    });
  }

  addToWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.addToWishList(slug, this);
    });
  }

  removeFromWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.removeFromWishList(slug, this);
    });
  }
}
