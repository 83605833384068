import { Component } from "../Component";
//import axios from "axios";
import Api from "../Helpers/Api";
import ShareApi from "../../Api/Share";
import AuthApi from "../../Api/Auth";

export default class App extends Component {
  persist = false;
  valuesInterval = 5 * 1 * 1000;

  constructor(props) {
    super(props);

    this.setId("app");
    this.getHelpers("const").set("app", this);
    this.setupInvalidResponse();
  }

  componentDidMount() {
    super.componentDidMount();

    window.onresize = () => {
      //this.defineTemplate();
    };

    this.defineTemplate().boot();
  }

  defineTemplate() {
    const currentScope = this.getHelpers("template").getScope();
    const newScope = this.getOnResizeResolusion(window.innerWidth);

    if (currentScope !== newScope) {
      this.getHelpers("template").setScope(newScope);
    }

    return this;
  }

  getOnResizeResolusion() {
    return "default";
  }

  setupInvalidResponse() {
    const app = this;

    Api.onInvalidResponse = function (r, err) {
      app.onInvalidResponse(r, err);
    };

    return this;
  }

  onInvalidResponse(r, err) {
    const { type } = r ? r.getData({}) : {};

    if (err) {
      this.onNetworkError(r, err);
    }

    if (type === "not-allowed") {
      this.onNotAllowedResponse(r, err);
    }
  }

  onNotAllowedResponse(r, err) {
    if (this.getPage().isFullAuth()) {
      this.getPage().redirectToLogin();
    }
  }

  onNetworkError(r, err) {
    this.getMainMessage((c) => {
      if (this.isOffline()) {
        c.setSeverity("warning").setMessage("no internet connection");
      } else {
        c.setSeverity("error").setMessage("network error occurred");
      }

      c.open();
    });
  }

  isOffline() {
    return !navigator.onLine;
  }

  async fetchValues() {
    const envHelper = this.getHelpers("env");
    const valueHelper = this.getHelpers("value");
    const stateHelper = this.getHelpers("state");
    const authHelper = this.getHelpers("auth");

    const app = envHelper.get("name");
    const account = envHelper.get("default_account", "automatapp");
    const repository = [envHelper.get("default_repository", app)].join("");

    const values = await ShareApi.content.values(account, repository);
    valueHelper.set(values.getData());

    const appVersion = valueHelper.getValue("app_version", 1) * 1;
    const loginVersion = valueHelper.getValue("login_version", 1) * 1;
    const currentAppVersion = stateHelper.get("app_version", 1) * 1;
    const currentLoginVersion = stateHelper.get("login_version", 1) * 1;

    const appValid = appVersion === currentAppVersion;
    const loginValid = loginVersion === currentLoginVersion;

    const valid = appValid && loginValid;

    if (!appValid || !loginValid) {
      stateHelper.set("app_version", appVersion);
      stateHelper.set("login_version", loginVersion);

      if (!loginValid) {
        authHelper.clear();
      }

      window.location.reload();
      // window.location.href = "/";
      return false;
    }

    return valid;
  }

  startFetchValues() {
    setInterval(() => {
      this.fetchValues();
    }, this.valuesInterval);
  }

  async boot() {
    try {
      const literalHelper = this.getHelpers("literal");
      const authHelper = this.getHelpers("auth");
      const valid = await this.fetchValues();

      if (valid) {
        const status = await AuthApi.status();
        authHelper.set(status.getData());
        await literalHelper.retrieve();
        this.setLoaded();
        this.notifyAuth({ boot: true });
        this.startFetchValues();
      }
    } catch (err) {
      console.log("app-boot: ", err);
    }

    /*
    axios
      .all([ShareApi.value.app(account, app)])
      .then(([values]) => {
        this.getHelpers("value").set(values.getData());
        return this.getHelpers("literal").retrieve();
      })
      .then(() => {
        this.setLoaded();
        return AuthApi.status();
      })
      .then((status) => {
        this.getHelpers("auth").set(status.getData());
        this.notifyAuth({ boot: true });
      })
      .catch((errors) => console.log("app-boot: ", errors));
      */
  }
}
