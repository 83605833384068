import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/results";
import Services from "../../Services/Services";

export default class Results extends Page {
  title = "home";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    Services.get("content").then(([contentService]) => {
      contentService
        .getCategories()
        .then((contentService) => {
          this.setData({
            "default.categories": contentService.getData("categories", []),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    Services.get("content").then(([contentService]) => {
      contentService
        .search(this.getPage().getParam("word", ""))
        .then((contentService) => {
          this.setData({
            "default.category_items": contentService.getData("search", []),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    this.getApp().addScripts();

    this.scrollToTop();
  }

  getCurrentWord() {
    return this.getPage().getParam("word", "");
  }
}
