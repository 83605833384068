import Helpers from "../../../modules/Core/Helpers/Helpers";
import Service from "../Service";

class RecentlyViewed extends Service {
  addToRecentlyViewed(slug) {
    const Env = Helpers.Env;
    const stateHelper = Helpers.State;
    // stateHelper.remove("viewed", {
    //   domain: Env.get("domain"),
    //   path: "/",
    // });

    // const start = Date.now() + 86400000;
    var array = [];

    var viewed = stateHelper.get("viewed", null);
    if (viewed) {
      array = viewed.split(",");
      if (!array.includes(slug)) {
        if (array.length === 10) {
          array.shift();
        }
        array.push(slug);
      }
      stateHelper.set("viewed", array.join(","), {
        domain: Env.get("domain"),
        // expires: new Date(start),
        path: "/",
      });
    } else {
      array.push(slug);
      stateHelper.set("viewed", array.join(","), {
        domain: Env.get("domain"),
        // expires: new Date(start),
        path: "/",
      });
    }
  }

  updateRecentlyViewed(slugs) {
    const Env = Helpers.Env;
    const stateHelper = Helpers.State;

    stateHelper.set("viewed", slugs.join(","), {
      domain: Env.get("domain"),
      path: "/",
    });
  }
}

export default new RecentlyViewed();
