import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();
  const sliders = component.getData("default.sliders", []);
  const closed = component.getHelpers("value").getValue("closed");

  return (
    <>
      {sliders.length !== 0 && (
        <div className="container">
          <div className="intro-slider-container">
            <div
              className="owl-carousel owl-simple owl-light owl-nav-inside"
              data-toggle="owl"
              data-owl-options='{"nav": false}'
            >
              {sliders.map((slider, index) => {
                const { slug, url, title, subtitle, btn_text } =
                  devHelper.getObjectValue(slider, "_values");
                return (
                  <a
                    href={url}
                    key={slug}
                    className="intro-slide"
                    style={{
                      backgroundImage: `url(${urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        slug
                      )})`,
                    }}
                  >
                    <div className="container intro-content ml-4">
                      <div style={{ float: index !== 0 ? "right" : "left" }}>
                        {title && <h1 className="intro-subtitle">{title}</h1>}
                        {subtitle && (
                          <h2
                            className="intro-title"
                            style={{ maxWidth: "480px" }}
                          >
                            {subtitle}
                          </h2>
                        )}
                        {btn_text && (
                          <div className="btn btn-primary home_slider">
                            <span>{btn_text}</span>
                            <i className="icon-long-arrow-right"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
            <span className="slider-loader text-white"></span>
          </div>
          {closed === "true" && (
            <div
              style={{
                textAlign: "center",
                fontSize: "22px",
                color: "#000",
              }}
              className="mt-3 mt-lg-5"
            >
              <div className="mb-2">{component.trans("header-message")}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ExportDefault;
