import React from "react";
import ProductRecentlyViewed from "../../../../Components/Widget/ProductRecentlyViewed";

const ExportDefault = (props) => {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const products = component.getData("default.products", []);

  return (
    <div>
      {products.length !== 0 && (
        <div className="container">
          <div className="heading heading-center mb-3">
            <h2 className="title-lg">Είδατε Πρόσφατα</h2>
          </div>
          <div
            className="owl-carousel owl-simple carousel-equal-height carousel-with-shadow"
            data-toggle="owl"
            data-owl-options='{
                                "nav": false, 
                                "dots": true,
                                "margin": 20,
                                "loop": false,
                                "responsive": {
                                    "0": {
                                        "items":2
                                    },
                                    "480": {
                                        "items":2
                                    },
                                    "768": {
                                        "items":3
                                    },
                                    "992": {
                                        "items":4
                                    },
                                    "1200": {
                                        "items":4,
                                        "nav": true,
                                        "dots": false
                                    }
                                }
                            }'
          >
            {products.map((item) => (
              <ProductRecentlyViewed
                key={devHelper.getObjectValue(item, "slug")}
                {...{
                  ...props,
                  ...{
                    product: item,
                  },
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportDefault;
