import React from "react";

export default function Poduct_List_View(props) {
  const { component } = props;

  const product = component.getData("default.product", null);
  const devHelper = component.getHelpers("dev");
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();
  const wishlist = component.getData("default.wishlist", false);

  //Add or Remove product from Wishlist
  const updateWishList = (boolean) => {
    var slug = devHelper.getObjectValue(product, "slug");
    if (boolean) {
      component.addToWishList(slug);
    } else {
      component.removeFromWishList(slug);
    }
  };

  return (
    <>
      {product && (
        <div className="product text-center">
          <figure className="product-media">
            <a
              href={`/product/${devHelper.getObjectValue(product, "slug")}`}
              style={{
                height: "250px",
                backgroundImage: `url(${component
                  .getHelpers("url")
                  .createAccountImageUrl(
                    account,
                    repository,
                    "item",
                    devHelper.getObjectValue(product, "slug"),
                    "default",
                    "1"
                  )})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {" "}
            </a>
            <div className="product-action-vertical">
              <button
                onClick={(e) => updateWishList(!wishlist)}
                className="btn-product-icon btn-expandable"
                style={{ border: "none" }}
              >
                <i className={wishlist ? "icon-heart" : "icon-heart-o"} />
                <span>
                  {wishlist
                    ? "Αφαίρεση απο Αγαπημένα"
                    : "Προσθήκη στα Αγαπημένα"}
                </span>
              </button>
            </div>
          </figure>
          <div className="product-body">
            <h3 className="product-title">
              <a href={`/product/${devHelper.getObjectValue(product, "slug")}`}>
                {devHelper.getObjectValue(product, "name")}
              </a>
            </h3>
            {product.price !== 0 && product.special_price !== 0 && (
              <div className="product-price">
                <span className="old-price">
                  {devHelper.getObjectValue(product, "price")} €
                </span>
                <span className="new-price">
                  {devHelper.getObjectValue(product, "special_price")} €
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
