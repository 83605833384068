import React from "react";
import Navbar from "../../../Components/Widget/Navbar";
import MobileDrawer from "../../../Components/Widget/MobileDrawer";
import FirstSection from "../widgets/bazaar/first_section";
import Footer from "../../../Components/Widget/Footer";
import Scroll from "../widgets/scroll";
import BazaarSlider from "../widgets/sliders/bazaar_slider";

const ExportDefault = (props) => {
  return (
    <>
      <div className="page-wrapper">
        <Navbar {...props} />
        <main className="main">
          <BazaarSlider {...props} />
          <FirstSection {...props} />
        </main>
        <Footer {...props} />
      </div>
      <Scroll {...props} />
      <MobileDrawer {...props} />
      {/* <Popup {...props} /> */}
    </>
  );
};

export default ExportDefault;
